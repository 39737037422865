import { AxiosRequestConfig } from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import { axiosGeneral } from '../api/axiosConfig';
import { IReduxStore, IUseApi, TAxiosMethod } from '../interfaces/IGeneral';
import { SET_IS_LOADING, SET_PROMISE } from '../constants/reduxActions';
import { TOKEN_DATA_KEY } from '../constants/login';
import { toast, ToastOptions } from 'react-toastify';
import { NOT_ALLOWED, NO_ACTIVE_SUBSCRIPTION, SOMETHING_WENT_WRONG } from '../constants/errors';
import NoActiveSubscriptionNotification, {
  noActiveSubscriptionOptions,
} from '../components/NoActiveSubscriptionNotification';
import { toCamelCase } from '../helpers/baseHelper';
import useGetTranslations from './useGetTranslations';
import { HOME_PAGE } from '../constants/routes';
import { useHistory } from 'react-router';

export function useApi(): IUseApi {
  const { isLoading } = useSelector((store: IReduxStore) => store.defaultReducer);
  const dispatch = useDispatch();
  const { translations } = useGetTranslations();
  const history = useHistory();

  const somethingWentWrong: ToastOptions = {
    toastId: 'someting-went-wrong',
    autoClose: 6000,
  };

  const notAllowedOptions: ToastOptions = {
    toastId: 'not-allowed-error',
    autoClose: 6000,
  };

  function handleApi<T>(method: TAxiosMethod, url: string, data?: T, noLoading?: boolean, noError?: boolean) {
    const promise = new Promise<T>((resolve, reject) => {
      let headers = {};
      const user = localStorage.getItem(TOKEN_DATA_KEY);
      if (user) headers = { authorization: `Bearer ${JSON.parse(user).access_token}` };
      let config: AxiosRequestConfig = { method, url, headers };
      if (data) {
        config = { ...config, data };
      }

      const request = axiosGeneral(config);
      if (!isLoading && !noLoading) {
        dispatch({ type: SET_IS_LOADING, payload: true });
      }

      request
        .then((response) => {
          if (response.data.code >= 400 && !noError) {
            showError(response.data.message, response.data.code);
          }
          resolve(response.data);
        })
        .catch((error) => reject(error.response));
    });
    dispatch({ type: SET_PROMISE, payload: promise });

    return promise;
  }

  const showToast = (message: string) => {
    Object.keys(translations).length > 0 && toast.error(message, somethingWentWrong);
  };

  function showError(error: any, code: number): void {
    switch (error) {
      case NO_ACTIVE_SUBSCRIPTION:
        toast.error(<NoActiveSubscriptionNotification />, noActiveSubscriptionOptions);
        break;
      case SOMETHING_WENT_WRONG:
        showToast(translations[toCamelCase(error)]);
        break;
      case NOT_ALLOWED:
        toast.error(translations[toCamelCase(error)], notAllowedOptions);
        break;
      default:
        if (translations[toCamelCase(error)] && translations[toCamelCase(error)].length > 3) {
          showToast(translations[toCamelCase(error)]);
          break;
        }
        if (code === 404) {
          history.replace(HOME_PAGE);
          break;
        }

        if (code === 408) {
          showToast(translations.connectionSlow);
          break;
        }

        if (error !== null) {
          //prettier-ignore
          toast.error(
            translations[toCamelCase(error.replace('api.', ''))]
              ? translations[toCamelCase(error.replace('api.', ''))]
              : error,
            somethingWentWrong
          );
        }
        break;
    }
  }

  return { handleApi };
}
