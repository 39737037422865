import { BrowserRouter as Router } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'cropperjs/dist/cropper.css';

import Footer from './Footer';
import StickyFooter from './StickyFooter';
import Header from './Header';
import Routes from './Routes';
import useTranslations from '../hooks/useTranslations';
import useProfile from '../hooks/useProfile';
import useGetClients from '../hooks/useGetClients';
import useGetInstruments from '../hooks/useGetInstruments';
import useCustomAds from '../hooks/useCustomAds';
import useMenus from '../hooks/useMenus';
import { IReduxStore } from '../interfaces/IGeneral';
import ReactLoader from './ReactLoader';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import style from '../assets/scss/components/App.module.scss';
import useCountries from '../hooks/useCountries';
import { GuidePopup } from './GuidePopup';

function App(): JSX.Element {
  const { isLoading } = useSelector((store: IReduxStore) => store.defaultReducer);
  const { toast } = convertKeysToCamelCase(style);

  useTranslations();
  useProfile();
  useMenus();
  useGetClients();
  useGetInstruments();
  useCustomAds();
  useCountries();

  return (
    <Router>
      <Header />
      <Routes />
      <StickyFooter />
      {isLoading && <ReactLoader />}
      <Footer />
      <ToastContainer hideProgressBar={true} transition={Slide} autoClose={3000} toastClassName={toast} />
      <GuidePopup />
    </Router>
  );
}

export default App;
